.explorer-container {
  position: absolute;
  display: grid;
  grid-template-rows: auto 1fr auto;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 10px 1px #0002;
}
.explorer-heading-name-icon-container {
  display: grid;
  height: 100%;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
}
.explorer-header {
  background: linear-gradient(rgb(53, 49, 50), rgb(50, 49, 50));
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  cursor: grab;
}
.explorer-header:active {
  cursor: grabbing;
}
.explorer-header-heading {
  margin: 0.2rem 0.5rem;
}
.explorer-header-btn-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin: 0.4rem 0.5rem;
}
.explorer-close-btn {
  height: 18px;
  width: 18px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.2s ease;
  font-size: 1.1rem;
}
.explorer-close-color {
  background-color: rgb(238, 81, 35);
}
.explorer-close-btn:hover {
  background-color: #fff4;
}
.explorer-close-icon-translate {
  transform: translateY(-2px);
}
.explorer-body {
  background-color: #fff;
  color: #212121;
  overflow: hidden;
  box-sizing: border-box;
}
.explorer-footer {
  border-top: 1px solid #0003;
  background-color: rgb(247, 247, 247);
  color: #212121;
  padding: 0.2rem 0.5rem;
  box-sizing: border-box;
}
.sc-EHOje.dMFuoo {
  overflow-x: hidden;
}
