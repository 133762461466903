.power-off-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 50;
  opacity: 0;
  animation: powerOff 1s ease forwards;
}
.power-off-inner-container {
  height: 100%;
  width: 100%;
  color: #fff;
  opacity: 0;
  animation: powerOff 1s ease forwards;
  animation-delay: 1s;
  box-sizing: border-box;
  padding: 0 1rem;
}
.power-off-loader-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
  width: max-content;
  margin: 0 auto;
}
.shutdown-loader-text {
  font-size: 1.8rem;
  font-weight: bolder;
  letter-spacing: 0.05rem;
}
@keyframes powerOff {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
