.heading {
  padding-right: 1rem;
  /* border-right: 1px dashed white; */
}
.task-list-container {
  display: flex;
  overflow: hidden;
}
.task-listing-activity {
  margin: 0 0.2rem;
  padding: 0.02rem 0.5rem;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: default;
  transition: 0.2s;
  border-radius: 2px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.3rem;
}
.task-listing-activity:hover {
  background-color: #fff3;
}
.task-listing-activity-active {
  background-color: #fff2;
}
.task-list-inner-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.2rem;
}
.loader-sm {
  r: 5px;
  cy: 10px;
  cx: 10px;
  stroke: var(--black);
  stroke-width: 2px;
  stroke-dasharray: 20 8;
  fill: var(--transparent);
}
.loader-sm-2 {
  r: 5px;
  cy: 8px;
  cx: 8px;
  stroke: var(--white);
  stroke-width: 2px;
  stroke-dasharray: 20 8;
  fill: var(--transparent);
}
.loader-lg {
  r: 10px;
  cy: 20px;
  cx: 20px;
  stroke: var(--white);
  stroke-width: 5px;
  fill: #0000;
  stroke-dasharray: 30 20;
}
.loader-rotate {
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.activity-inner-container {
  border-radius: 3px;
  padding: 0.5rem 0 !important;
  box-sizing: border-box;
  transform: translateY(-3px) !important;
}
.activity-list-container {
  max-width: 200px !important;
  top: 34px !important;
  left: 66px !important;
}
.activity-list-nav-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.5rem;
}
.down-caret-arrow-translate {
  transform: translateY(-2px);
}
.activity-close-btn {
  height: 16px;
  width: 16px;
  text-align: center;
  border-radius: 50%;
  color: var(--lightColor);
  cursor: pointer;
  transition: 0.2s ease;
}
.activity-close-btn:hover {
  background-color: var(--orangeColor);
  color: var(--white);
}
