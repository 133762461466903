.drop-down-container {
  position: absolute;
  right: 10px;
  top: 34px;
  width: 100%;
  max-width: 300px;
  display: grid;
  grid-template-rows: auto 1fr;
  z-index: 10;
}
.drop-drop-caret-pointed-container {
  transform: rotate(180deg);
}
.flex-end {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-left: 1.5rem;
}
.drop-drop-caret-pointed {
  transform: translateX(10px);
}
.drop-down-inner-container {
  box-shadow: 0 2px 5px 0 #0002;
  background-color: var(--darkColor);
  color: var(--lightColor);
  border-radius: 5px;
  transform: translateY(-7px);
  padding: 0.8rem 0;
  box-sizing: border-box;
}
.drop-down-items {
  max-height: 30.4px;
  box-sizing: border-box;
  padding: 0.4rem 1rem;
  transition: 0.2s ease;
}
.drop-down-items:hover {
  background-color: #0002;
}
.network-dot {
  height: 10px;
  width: 10px;
  margin-right: 0.5rem;
  border-radius: 50%;
  display: inline-block;
}
.dropdown-hr {
  height: 1px;
  width: 30px;
  background-color: #aaa;
  margin: 0.5rem auto;
}
.drop-down-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
}
.brightness-scroll-line {
  height: 2px;
  width: 100%;
  background-color: #666;
  display: flex;
}
.no-cursor {
  cursor: default;
}
.brightness-scroll-line::-webkit-slider-thumb {
  height: 15px;
  border-radius: 50%;
  width: 15px;
  background: #fff;
  box-shadow: 0 0 2px 0 #777;
  cursor: pointer;
}
