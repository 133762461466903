@import url(https://fonts.googleapis.com/css2?family=Potta+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
.dialog-overlay {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: absolute;
}
.dialog-overlay {
  background-color: #0004;
  z-index: 20;
}
.dialog-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  max-width: 500px;
  margin: 0 auto;
  color: #212121;
  border-radius: 5px;
  z-index: 21;
  width: 100%;
  max-width: 500px;
  border: 1px solid #0002;
  box-sizing: border-box;
}
.dialog-header {
  background-color: #00000008;
  border-bottom: 1px solid #0002;
  padding: 0.6rem 0.8rem;
  font-size: 1.1rem;
  font-weight: bolder;
  letter-spacing: 0.05rem;
  box-sizing: border-box;
}
.dialog-body {
  padding: 1rem;
  box-sizing: border-box;
  border-bottom: 1px solid #0002;
}
.dialog-footer {
  font-weight: bolder;
  background-color: #00000008;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.dialog-footer-btn {
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease;
  color: #212121;
  box-sizing: border-box;
}
.dialog-footer-btn:first-child {
  border-right: 1px solid #0002;
}
.dialog-footer-btn:hover {
  background-color: #00000020;
}

.heading {
  padding-right: 1rem;
  /* border-right: 1px dashed white; */
}
.task-list-container {
  display: flex;
  overflow: hidden;
}
.task-listing-activity {
  margin: 0 0.2rem;
  padding: 0.02rem 0.5rem;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: default;
  transition: 0.2s;
  border-radius: 2px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}
.task-listing-activity:hover {
  background-color: #fff3;
}
.task-listing-activity-active {
  background-color: #fff2;
}
.task-list-inner-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.2rem;
  gap: 0.2rem;
}
.loader-sm {
  r: 5px;
  cy: 10px;
  cx: 10px;
  stroke: var(--black);
  stroke-width: 2px;
  stroke-dasharray: 20 8;
  fill: var(--transparent);
}
.loader-sm-2 {
  r: 5px;
  cy: 8px;
  cx: 8px;
  stroke: var(--white);
  stroke-width: 2px;
  stroke-dasharray: 20 8;
  fill: var(--transparent);
}
.loader-lg {
  r: 10px;
  cy: 20px;
  cx: 20px;
  stroke: var(--white);
  stroke-width: 5px;
  fill: #0000;
  stroke-dasharray: 30 20;
}
.loader-rotate {
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.activity-inner-container {
  border-radius: 3px;
  padding: 0.5rem 0 !important;
  box-sizing: border-box;
  transform: translateY(-3px) !important;
}
.activity-list-container {
  max-width: 200px !important;
  top: 34px !important;
  left: 66px !important;
}
.activity-list-nav-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.down-caret-arrow-translate {
  transform: translateY(-2px);
}
.activity-close-btn {
  height: 16px;
  width: 16px;
  text-align: center;
  border-radius: 50%;
  color: var(--lightColor);
  cursor: pointer;
  transition: 0.2s ease;
}
.activity-close-btn:hover {
  background-color: var(--orangeColor);
  color: var(--white);
}

.drop-down-container {
  position: absolute;
  right: 10px;
  top: 34px;
  width: 100%;
  max-width: 300px;
  display: grid;
  grid-template-rows: auto 1fr;
  z-index: 10;
}
.drop-drop-caret-pointed-container {
  transform: rotate(180deg);
}
.flex-end {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-left: 1.5rem;
}
.drop-drop-caret-pointed {
  transform: translateX(10px);
}
.drop-down-inner-container {
  box-shadow: 0 2px 5px 0 #0002;
  background-color: var(--darkColor);
  color: var(--lightColor);
  border-radius: 5px;
  transform: translateY(-7px);
  padding: 0.8rem 0;
  box-sizing: border-box;
}
.drop-down-items {
  max-height: 30.4px;
  box-sizing: border-box;
  padding: 0.4rem 1rem;
  transition: 0.2s ease;
}
.drop-down-items:hover {
  background-color: #0002;
}
.network-dot {
  height: 10px;
  width: 10px;
  margin-right: 0.5rem;
  border-radius: 50%;
  display: inline-block;
}
.dropdown-hr {
  height: 1px;
  width: 30px;
  background-color: #aaa;
  margin: 0.5rem auto;
}
.drop-down-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  gap: 1rem;
}
.brightness-scroll-line {
  height: 2px;
  width: 100%;
  background-color: #666;
  display: flex;
}
.no-cursor {
  cursor: default;
}
.brightness-scroll-line::-webkit-slider-thumb {
  height: 15px;
  border-radius: 50%;
  width: 15px;
  background: #fff;
  box-shadow: 0 0 2px 0 #777;
  cursor: pointer;
}

.taskbar-container {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 7px 2px #0009;
  font-size: 0.9rem;
  padding: 0.4rem 0.5rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.right-task-item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.right-displayed-container {
  cursor: pointer;
  transition: 0.2s ease;
  display: grid;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  grid-template-columns: repeat(5, auto);
}
.right-displayed-container-after {
  content: "";
  display: block;
  background-color: #fff;
  width: 125px;
  height: 3px;
  position: absolute;
  border-radius: 10px;
  transform: translateY(5px);
}
.drop-caret {
  transition: 0.2s ease;
}
.drop-caret-up {
  transform: rotate(180deg);
}

.power-off-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 50;
  opacity: 0;
  animation: powerOff 1s ease forwards;
}
.power-off-inner-container {
  height: 100%;
  width: 100%;
  color: #fff;
  opacity: 0;
  animation: powerOff 1s ease forwards;
  animation-delay: 1s;
  box-sizing: border-box;
  padding: 0 1rem;
}
.power-off-loader-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  gap: 1rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
}
.shutdown-loader-text {
  font-size: 1.8rem;
  font-weight: bolder;
  letter-spacing: 0.05rem;
}
@keyframes powerOff {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.terminal-editable-container {
  height: 100%;
  width: 100%;
  background-color: rgb(45, 9, 34);
  color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.9rem;
  cursor: text;
}
.terminal-editable-container::-webkit-scrollbar {
  width: 5px;
}
.terminal-editable-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #888;
}
.terminal-green {
  color: rgba(124, 227, 53);
}
.terminal-blue {
  color: rgb(86, 137, 211);
}
.terminal-main-content {
  margin: 0.5rem 0;
}
.terminal-text-editor {
  padding: 0 0.5rem;
  box-sizing: border-box;
}
.terminal-text-editor:focus {
  outline: none;
}
.terminal-red {
  color: rgb(255, 0, 25);
}
.terminal-output {
  margin: 0.3rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
}
.terminal-help-grid {
  display: grid;
  grid-template-columns: 0.7fr auto 2fr;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.terminal-file-system-grid {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}
@media screen and (min-width: 500px) {
  .terminal-file-system-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 800px) {
  .terminal-file-system-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

.browser-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}
.browser-task-container {
  /* background-color: aqua; */
  border-bottom: 0.05rem solid #00000015;
  padding: 0.3rem 0.5rem;
  display: grid;
  box-sizing: border-box;
  grid-template-columns: auto 1fr auto;
}
.browser-task-image-container > img {
  width: 15px;
  cursor: pointer;
}
.browser-container > iframe {
  height: 100%;
  width: 100%;
  border: none;
}
.browser-loader {
  height: calc(100% - 66px);
  width: 100%;
  position: absolute;
  top: 66px;
  text-align: center;
  z-index: 100000;
  background-color: #eee;
}
.loader-lg-browser {
  r: 20px;
  cy: 40px;
  cx: 40px;
  stroke: #0005;
  stroke-width: 5px;
  fill: #0000;
  stroke-dasharray: 60 40;
}
input.browser-search-input {
  border: 1px solid #0002;
  padding: 0.2rem 0.4rem;
  margin: 0 1rem;
  box-sizing: border-box;
  border-radius: 30px !important;
}
.browser-search-input:focus {
  outline: none;
}

.toggle-button-container {
  width: 50px;
  height: 30px;
  border-radius: 30px;
  box-sizing: border-box;
  background-color: #0002;
  cursor: pointer;
  transition: 0.2s ease;
}
.toggle-button {
  --dimension: 24px;
  transition: 0.2s ease;
  margin: 3px;
  height: var(--dimension);
  width: var(--dimension);
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgb(0, 0, 0, 0.2);
  border-radius: 50%;
}
.toggle-button-container-active {
  background-color: rgb(234, 84, 33, 1);
}
.toggle-button-active {
  transform: translateX(20px);
}

.settings-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
}
.settings-left-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem 0;
  box-sizing: border-box;
  border-right: 1px solid rgb(207, 207, 207);
}
.settings-left-button {
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  cursor: pointer;
}
.settings-left-button-active {
  background-color: rgb(234, 84, 33, 0.2);
}
.settings-left-button:hover {
  background-color: rgb(234, 84, 33);
  color: #fff;
}
.settings-left-button:active {
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}
.settings-right-container {
  background-color: rgb(247, 247, 247);
}
.settings-right-inner-container {
  width: 90% !important;
  margin: 0 auto;
}
.settings-right-inner-container-header {
  font-size: 1rem;
  font-weight: bold;
  margin: 1rem 0 0.5rem 0;
}
.settings-right-inner-container-bottom {
  width: 100%;
  background-color: #fff;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 3px;
}

/* Theme */
.theme-change-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  /* display: flex;
  width: 100%;
  flex-shrink: 0;
  justify-content: space-between; */
}
.theme-change-division {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 130px;
}
.theme-change-division:hover {
  background-color: rgb(26, 115, 232, 0.8);
  color: #fff;
}
.theme-change-background {
  border-radius: 5px;
  height: 90px;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  background-color: aliceblue;
  color: #666;
  transition: 0.2s ease;
}
.theme-change-division-active {
  background-color: rgb(26, 115, 232, 0.3);
}
.theme-change-name {
  margin: 0.2rem 0;
  text-align: center;
}

/* Reset To Default */
.reset-button {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 0.2rem 0.6rem;
  margin: 0.5rem 0;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #0004;
}
.reset-button:hover {
  background-color: #0001;
}
.reset-button:active {
  background-color: #0002;
}

/* Personalise */
.personalise-2-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem 0;
}
.personalise-2-grid:first-child {
  margin-top: 0;
}
.personalise-2-grid:last-child {
  margin-bottom: 0;
}
.personalise-text {
  font-size: 1rem;
  color: #000c;
}

.desktop-icon-container {
  height: 90px;
  width: 80px;
  margin: 0 0.65rem 0.65rem 0;
  box-sizing: border-box;
  border-radius: 3px;
  transition: 0.1s ease;
}
.desktop-icon-container:hover {
  border: 1px solid #fff7;
  background-color: #fff2;
}
.desktop-icon-container:active {
  background-color: #fff5;
}
.desktop-icon {
  height: 100%;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.desktop-icon-text {
  /* display: inline-block; */
  width: 75px;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.explorer-container {
  position: absolute;
  display: grid;
  grid-template-rows: auto 1fr auto;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 10px 1px #0002;
}
.explorer-heading-name-icon-container {
  display: grid;
  height: 100%;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.explorer-header {
  background: linear-gradient(rgb(53, 49, 50), rgb(50, 49, 50));
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  gap: 1rem;
  cursor: -webkit-grab;
  cursor: grab;
}
.explorer-header:active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.explorer-header-heading {
  margin: 0.2rem 0.5rem;
}
.explorer-header-btn-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0.4rem 0.5rem;
}
.explorer-close-btn {
  height: 18px;
  width: 18px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.2s ease;
  font-size: 1.1rem;
}
.explorer-close-color {
  background-color: rgb(238, 81, 35);
}
.explorer-close-btn:hover {
  background-color: #fff4;
}
.explorer-close-icon-translate {
  transform: translateY(-2px);
}
.explorer-body {
  background-color: #fff;
  color: #212121;
  overflow: hidden;
  box-sizing: border-box;
}
.explorer-footer {
  border-top: 1px solid #0003;
  background-color: rgb(247, 247, 247);
  color: #212121;
  padding: 0.2rem 0.5rem;
  box-sizing: border-box;
}
.sc-EHOje.dMFuoo {
  overflow-x: hidden;
}

.desktop-area-container {
  height: 100%;
  width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(10, auto) 1fr;
  animation: fade-in 0.2s ease-in forwards;
  opacity: 0;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.link-footer > a {
  color: #666;
  text-decoration: none;
}
.link-footer > a:hover {
  text-decoration: underline;
}
.portfolio-container-iframe {
  height: 100%;
  width: 100%;
  border: none;
}
.new-file-folder-input {
  border: none;
  padding: 0.4rem 0.6rem;
  border: 1px solid #0002;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 1rem;
  width: 100%;
}
.new-file-folder-input:focus {
  outline: none;
}

/* Context Menu */
.context-menu-container {
  position: absolute;
  height: 300px;
  width: 230px;
  color: var(--lightColor);
  background-color: var(--darkColor);
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #0006;
  animation: opacAnim 0.2s ease;
  overflow: hidden;
}
@keyframes opacAnim {
  from {
    transform: scaleY(0.8);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}
.context-menu-content {
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s ease;
  border-radius: 5px;
}
.context-menu-content:hover {
  background-color: var(--orangeColor);
  color: var(--white);
}
.context-menu-content:active {
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

.start-menu-container {
  background-color: #0005;
  animation: fade-in 0.2s ease-in forwards;
  opacity: 0;
  height: calc(100vh - 30px);
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.search-field-container {
  margin: 1rem 0;
  text-align: center;
}
.start-menu-container-grid {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-rows: auto 1fr auto;
  height: calc(100vh - 31px);
}
input.search-field {
  margin: 0 auto;
  border-radius: 0 30px 30px 0;
  box-sizing: border-box;
  border: none;
  width: 100%;
  color: #555;
  padding: 0.3rem 0;
  padding-right: 0.6rem;
}
input.search-field::-webkit-input-placeholder {
  color: #0006;
}
input.search-field:-ms-input-placeholder {
  color: #0006;
}
input.search-field::placeholder {
  color: #0006;
}
input.search-field:focus {
  outline: none;
}
.search-field-grid {
  display: grid;
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
  grid-template-columns: auto 1fr;
}
.search-bar-icon-container {
  border-radius: 30px 0 0 30px;
  background-color: #fff;
  text-align: center;
  box-sizing: border-box;
  padding: auto 0.8rem;
}
.search-bar-icon {
  margin-left: 0.6rem;
  margin-right: 0.5rem;
  cursor: text;
}
.start-application-container {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.start-menu-item {
  height: 120px;
  width: 110px;
  padding: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  transition: 0.2s ease;
  font-size: 1.2rem;
  border-radius: 3px;
}
.start-menu-item:hover {
  background-color: #ffffff15;
  box-shadow: inset 1px 2px 10px 0 #ffffff15;
}
.start-menu-footer-grid {
  display: grid;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem auto;
  grid-template-columns: repeat(2, auto);
}
.start-menu-footer-item {
  border-bottom: 0.2rem solid #0000;
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff8;
  transition: 0.2s ease;
}
.start-menu-footer-item:hover {
  color: #fff;
}
.start-menu-footer-item-active {
  color: #fff;
  border-color: #ea5421;
}
@media screen and (min-width: 600px) {
  .start-application-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1000px) {
  .start-application-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.lower-desktop-grid {
  display: grid;
  grid-template-columns: auto 1fr;
}
.left-navigation-bar {
  width: 60px;
  display: grid;
  grid-template-rows: 1fr auto;
  box-sizing: border-box;
  background-color: #0004;
}
.left-nav-item {
  cursor: pointer;
  height: 50px;
  width: 50px;
  margin: 0.5rem auto;
  border-radius: 5px;
  text-align: center;
  transition: 0.1s ease-in-out;
}
.left-nav-item:hover {
  background-color: #fff4;
}
.left-nav-item-active {
  background-color: #fff2;
}
.left-nav-item:active {
  transform: scale(0.95);
}
.start-icon-svg {
  stroke: white;
  stroke-width: 4;
  transition: 0.2s ease-in-out;
  fill: #0000;
  r: 15px;
}
.start-icon-container:hover .start-icon-svg {
  r: 10px;
  stroke-width: 20;
}
.nav-item-image {
  height: auto;
  /* width: 40px; */
  margin: 0 auto;
}

.desktop-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: white;
}
.image-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.desktop-taskbar-grid {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}

/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap"); */

* {
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  --darkColor: #393f3f;
  --lightColor: #bbc2c0;
  --orangeColor: #ea5421f2;
  --white: #fff;
  --black: #000;
  --transparent: #0000;
}
.font-roboto {
  font-family: 'Roboto', sans-serif;
}
.font-potta {
  font-family: 'Potta One', cursive;
}
.font-raleway {
  font-family: 'Raleway', sans-serif;
}
.font-lobster {
  font-family: 'Lobster', cursive;
}
.font-times {
  font-family: 'Times New Roman', Times, serif;
}
.font-courier {
  font-family: 'Courier New', Courier, monospace;
}
.centralise {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.display-none {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
.text-area-editor {
  height: 100%;
  width: 100%;
  resize: none;
  border: none;
  box-sizing: border-box;
  padding: 0.2rem 0.4rem;
}
.text-area-editor:focus {
  outline: none;
}
.text-area-editor::-webkit-scrollbar {
  width: 10px;
}
.text-area-editor::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: rgba(234, 84, 33, 0.9);
  border-radius: 10px;
}
.container-center {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  .container-center {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

