.lower-desktop-grid {
  display: grid;
  grid-template-columns: auto 1fr;
}
.left-navigation-bar {
  width: 60px;
  display: grid;
  grid-template-rows: 1fr auto;
  box-sizing: border-box;
  background-color: #0004;
}
.left-nav-item {
  cursor: pointer;
  height: 50px;
  width: 50px;
  margin: 0.5rem auto;
  border-radius: 5px;
  text-align: center;
  transition: 0.1s ease-in-out;
}
.left-nav-item:hover {
  background-color: #fff4;
}
.left-nav-item-active {
  background-color: #fff2;
}
.left-nav-item:active {
  transform: scale(0.95);
}
.start-icon-svg {
  stroke: white;
  stroke-width: 4;
  transition: 0.2s ease-in-out;
  fill: #0000;
  r: 15px;
}
.start-icon-container:hover .start-icon-svg {
  r: 10px;
  stroke-width: 20;
}
.nav-item-image {
  height: auto;
  /* width: 40px; */
  margin: 0 auto;
}
