.desktop-area-container {
  height: 100%;
  width: 100%;
  padding: 0.8rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(10, auto) 1fr;
  animation: fade-in 0.2s ease-in forwards;
  opacity: 0;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.link-footer > a {
  color: #666;
  text-decoration: none;
}
.link-footer > a:hover {
  text-decoration: underline;
}
.portfolio-container-iframe {
  height: 100%;
  width: 100%;
  border: none;
}
.new-file-folder-input {
  border: none;
  padding: 0.4rem 0.6rem;
  border: 1px solid #0002;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 1rem;
  width: 100%;
}
.new-file-folder-input:focus {
  outline: none;
}

/* Context Menu */
.context-menu-container {
  position: absolute;
  height: 300px;
  width: 230px;
  color: var(--lightColor);
  background-color: var(--darkColor);
  border-radius: 5px;
  box-shadow: 0 0 10px 0 #0006;
  animation: opacAnim 0.2s ease;
  overflow: hidden;
}
@keyframes opacAnim {
  from {
    transform: scaleY(0.8);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}
.context-menu-content {
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s ease;
  border-radius: 5px;
}
.context-menu-content:hover {
  background-color: var(--orangeColor);
  color: var(--white);
}
.context-menu-content:active {
  filter: brightness(0.8);
}
