.toggle-button-container {
  width: 50px;
  height: 30px;
  border-radius: 30px;
  box-sizing: border-box;
  background-color: #0002;
  cursor: pointer;
  transition: 0.2s ease;
}
.toggle-button {
  --dimension: 24px;
  transition: 0.2s ease;
  margin: 3px;
  height: var(--dimension);
  width: var(--dimension);
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgb(0, 0, 0, 0.2);
  border-radius: 50%;
}
.toggle-button-container-active {
  background-color: rgb(234, 84, 33, 1);
}
.toggle-button-active {
  transform: translateX(20px);
}
