.desktop-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: white;
}
.image-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.desktop-taskbar-grid {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}
